import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import AOS from "aos";
import "aos/dist/aos.css";
import 'sweetalert2/dist/sweetalert2.min.css'

import './assets/js/custom';



import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Default from './pages/common/Default';
import Home from './pages/Home';
import { useEffect } from 'react';
import Book from './pages/Book';
import Bibliography from './pages/Bibliography';
import AwardHonors from './pages/AwardHonors';
import NewsEvents from './pages/NewsEvents';
import BlogsArticles from './pages/BlogsArticles';
import BlogsArticlesDetails from './pages/BlogsArticlesDetails';
import NewsEventsDetails from './pages/NewsEventsDetails';
import AwardHonorsDetails from './pages/AwardHonorsDetails';
import ContactUs from './pages/ContactUs';

import ScrollToTop from './pages/ScrollToTop';
import ApiService from './services/ApiService';


function App() {

  useEffect(() => {
    AOS.init();
    // AOS.refresh();

    //////  visiter track //////////
    const visiter_add = () => {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(async (data) => {
          console.log(data.ip)
          await new ApiService().POST('/visiter-submit', { client_ip: data.ip });
        })
        .catch(error => console.log(error))
    }

    return () => visiter_add();
  }, [])


  return (
    <>
      <BrowserRouter>

        {/* ScrollToTop component */}
        <ScrollToTop />


        <Routes>
          <Route path='/' element={<Default />} >
            <Route index element={<Home />} />
            <Route path='/books' element={<Book />} />
            <Route path='/bibliography' element={<Bibliography />} />
            <Route path='/award-honors' element={<AwardHonors />} />
            <Route path='/award-honors-details' element={<AwardHonorsDetails />} />
            <Route path='/news-events' element={<NewsEvents />} />
            <Route path='/news-events-details' element={<NewsEventsDetails />} />
            <Route path='/blogs-articles' element={<BlogsArticles />} />
            <Route path='/blogs-articles-details' element={<BlogsArticlesDetails />} />
            <Route path='/contact-me' element={<ContactUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
