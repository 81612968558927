import { Link } from "react-router-dom"

import ReactOwlCarousel from "react-owl-carousel";
import { useQuery } from "@tanstack/react-query";
import ApiService from "../services/ApiService";
import { Helmet } from "react-helmet";
import moment from "moment";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";


function BlogsArticles() {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData,cmsLoading } = useSelector(state => state.Cms);


    const { data: blogData } = useQuery({
        queryKey: ['blogData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/blog");
            return response.data;
        },
    });

    const displayBlog = (item) => {

        return (<div className="colmn_card">
            <div>
                <img src={item.image} alt="blog" />
            </div>
            <div className="card_body">
                <h5 className="title">{item.title}</h5>
                {item.short_description && parse(item.short_description)}
                <ul className="colmn_card-nav">
                    <li>
                        <Link to="/blogs-articles-details" state={{ id: item.id }}>Read More..</Link>
                    </li>
                    <li className="post_date">{item.created_at && moment(item.created_at).format('MMMM D, YYYY')}</li>
                </ul>
            </div>
        </div>);

    }



    return (<>

        {/* ////////// page title set /////////////////// */}
        <Helmet>
            <title>DG || Blogs</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
        {/* ///////// page title set End //////////////// */}

        {/* Inner-banner-start=====*/}
        {/* <section
            className="inner-banner"
            style={{ backgroundImage: `url("${news_events_bg}")` }}
        >
            <div className="container">
                <div className="content">
                    <h1>Blogs &amp; Articles</h1>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb m-0 justify-content-center">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Blogs &amp; Articles
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section> */}
        <section className="hero">

            {cmsData?.data?.cms_banner?.blogs_page && <ReactOwlCarousel
                className='owl-theme'
                loop={true}
                margin={0}
                responsiveClass={true}
                nav={false}
                dots={false}
                autoplay={true}
                autoplayTimeout={5000}

                responsive={{
                    0: {
                        items: 1,

                    },
                    768: {
                        items: 1,

                    },
                    1000: {
                        items: 1
                    }
                }}
            >
                {cmsData?.data?.cms_banner?.blogs_page?.map((item, index) => (<div key={index}>
                    <div className="item">
                        <div
                            className="hero-bg"
                            style={{ backgroundImage: `url(${item.image})`, height: "400px" }}
                        />
                    </div>
                </div>))}
            </ReactOwlCarousel>}

        </section >
        {/* Inner-banner-end=====*/}



        {/* Articles-start=====*/}
        <section className="articles p-top p-bottom">
            <div className="container custom_container">
                <div className="mb-3">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="mb-2">
                                <div className="row">

                                    {blogData?.latest_data?.item_1 && <>
                                        <div className="col-md-6 d-flex">
                                            {displayBlog(blogData?.latest_data?.item_1)}
                                        </div>
                                    </>}


                                    {blogData?.latest_data?.item_2 && <>
                                        <div className="col-md-6 d-flex">
                                            {displayBlog(blogData?.latest_data?.item_2)}
                                        </div>
                                    </>}


                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="article_row ">

                                {blogData?.old_data?.map((item, index) => (<Link to="/blogs-articles-details" state={{ id: item.id }} key={index} className="article_colmn">
                                    <div className="art_thumb">
                                        <img src={item.image} alt="blog" />
                                    </div>
                                    <div className="art_content">
                                        <h6 className="title">{moment(item.created_at).format('MMMM D, YYYY')}</h6>
                                        <p>{item.title}</p>
                                    </div>
                                </Link>))}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="row">
                        {blogData?.latest_data?.item_3 && <> <div className="col-lg-8">
                            <div className="post_bgImg_wrap">
                                <Link to="/blogs-articles-details" state={{ id: blogData?.latest_data?.item_3?.id }} >
                                    <img src={blogData?.latest_data?.item_3?.image} alt="blog" />
                                    <p className="post_tag">
                                        {blogData?.latest_data?.item_3?.title}
                                        <label className="post_date">{blogData?.latest_data?.item_3?.created_at && moment(blogData?.latest_data?.item_3?.created_at).format('MMMM D, YYYY')}</label>
                                    </p>
                                </Link>
                            </div>
                        </div>
                        </>}

                        {blogData?.latest_data?.item_4 && <> <div className="col-lg-4">
                            {displayBlog(blogData?.latest_data?.item_4)}
                        </div>
                        </>}

                    </div>
                </div>

                <div className="row">

                    {blogData?.latest_data?.item_5 && <>  <div className="col-md-4 d-flex">
                        {displayBlog(blogData?.latest_data?.item_5)}
                    </div>
                    </>}

                    {blogData?.latest_data?.item_6 && <> <div className="col-md-4 d-flex">
                        {displayBlog(blogData?.latest_data?.item_6)}
                    </div>
                    </>}

                    {blogData?.latest_data?.item_7 && <> <div className="col-md-4 d-flex">
                        {displayBlog(blogData?.latest_data?.item_7)}
                    </div>
                    </>}

                </div>
            </div>
        </section>
        {/* Articles-end=====*/}
    </>
    )
}

export default BlogsArticles