
import { Link } from "react-router-dom";
import ReactOwlCarousel from 'react-owl-carousel';
// import { useGetCmsContentQuery } from '../redux/query/cmsApi';
// import { useGetBooksQuery } from '../redux/query/bookApi';

import i_tech_icon from '../assets/images/icon/i-tech-icon.svg';

import { useQuery } from '@tanstack/react-query';
import ApiService from '../services/ApiService';
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import moment from "moment";
import { useSelector } from "react-redux";



function Home() {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData, cmsLoading } = useSelector(state => state.Cms);


    const { data: bookData } = useQuery({
        queryKey: ['home_bookData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/book");
            if (response.data.status) {
                return response.data.data;
            } else {
                return response.data;
            }

        },
    });


    const { data: eventData } = useQuery({
        queryKey: ['home_eventData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/event?page=1&perPage=3");
            if (response.data.status) {
                return response.data.data;
            } else {
                return response.data;
            }
        },
    });



    const titleSplit = (data) => {
        const firstSpaceIndex = data.indexOf(' ');

        if (firstSpaceIndex !== -1) {
            const firstPart = data.slice(0, firstSpaceIndex);
            const secondPart = data.slice(firstSpaceIndex + 1);

            return parse(`${firstPart} <span>${secondPart}</span>`);

        } else {
            return parse(data);
        }
    }



    return (<>

        {/* ////////// page title set /////////////////// */}
        <Helmet>
            <title>DG || Home</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
        {/* ///////// page title set End //////////////// */}

        {/* Hero-section-start=====*/}
        <section className="hero">

            {cmsData?.data?.cms_banner?.home_page && <ReactOwlCarousel
                className='owl-theme'
                loop={true}
                margin={0}
                responsiveClass={true}
                nav={true}
                navText={['<', '>']}
                dots={false}
                autoplay={true}
                autoplayTimeout={10000}

                responsive={{
                    0: {
                        items: 1,

                    },
                    768: {
                        items: 1,

                    },
                    1000: {
                        items: 1
                    }
                }}
            >
                {cmsData?.data?.cms_banner?.home_page?.map((item, index) => (<div key={index}>
                    <div className="item">
                        <div
                            className="hero-bg"
                            style={{ backgroundImage: `url(${item.image})` }}
                        >
                            <div className="container">
                                <div className="content">
                                    <span>{cmsData?.data?.cms_banner?.home_page?.title_1}</span>
                                    <h1>{cmsData?.data?.cms_banner?.home_page?.title_2}</h1>
                                    <p>{cmsData?.data?.cms_banner?.home_page?.details}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>))}
            </ReactOwlCarousel>}

        </section >
        {/* Hero-section-end=====*/}


        {/* About-me-start=====*/}
        <section className="about-me p-top p-bottom position-relative">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div
                            className="image-side"
                            data-aos="fade-right"
                            data-aos-offset={50}
                            data-aos-easing="ease-in-sine"
                        >
                            {cmsData?.data?.home_page_content?.about?.image_1 && <img
                                src={cmsData?.data?.home_page_content?.about?.image_1}
                                alt="about-me"
                                className="img-fluid"
                            />}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="abt-text">
                            {/* <h2 className="uni-text" data-aos="zoom-in">
                                About <span>Me</span>
                            </h2> */}
                            {cmsData?.data?.home_page_content?.about?.head_title && parse(cmsData?.data?.home_page_content?.about?.head_title)}
                            <small>{cmsData?.data?.home_page_content?.about?.title_1}</small>
                            {cmsData?.data?.home_page_content?.about?.content && parse(cmsData?.data?.home_page_content?.about?.content)}
                            <Link to={cmsData?.data?.home_page_content?.about?.file_1} target='_blank' className=" btn-primary">
                                Curriculum vitae (CV)
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {cmsData?.data?.home_page_content?.about?.image_2 && <img
                className="bg-ovelay img-fluid"
                src={cmsData?.data?.home_page_content?.about?.image_2}
                alt="about-me-overlay"
            />}
        </section>
        {/* About-me-end=====*/}


        {/* What-itech-Start=====*/}
        <section className="what-itech">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="what-i-text abt-text">
                            {/* <h2 className="uni-text">
                                {cmsData?.data?.home_page_content?.what_i_teach?.title_1 && titleSplit(cmsData?.data?.home_page_content?.what_i_teach?.title_1)}
                            </h2> */}

                            {cmsData?.data?.home_page_content?.what_i_teach?.head_title && parse(cmsData?.data?.home_page_content?.what_i_teach?.head_title)}

                            <p>{cmsData?.data?.home_page_content?.what_i_teach?.content && parse(cmsData?.data?.home_page_content?.what_i_teach?.content)}</p>
                        </div>
                        <div className="i-tech-bx">
                            <ul>
                                {cmsData?.data?.home_page_content?.what_i_teach?.badge_data?.map((item, index) => (<li key={index}>
                                    <div className="round-shape" onClick={() => item.badge_file !== null && window.open(item.badge_file)}>
                                        <img
                                            src={i_tech_icon}
                                            alt="icon"
                                            className="img-fluid"
                                        />
                                        <h6>{item.badge_text}</h6>
                                    </div>
                                </li>))}
                            </ul>
                        </div>

                    </div>
                    {/* <div className="col-lg-4">
                        <div className="img-side" data-aos="zoom-out-left">
                            <img
                                src={cmsData?.data?.home_page_content?.what_i_teach?.image_1}
                                alt="i-tech"
                                className="img-fluid"
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
        {/* What-itech-End=====*/}


        {/*My-experties-Star=====*/}
        <section className="my-experties p-top p-bottom">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-4 mb-lg-0">
                        <div className="img-side" data-aos="zoom-out-right">
                            <img
                                src={cmsData?.data?.home_page_content?.my_expertise?.image_1}
                                alt="experties"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ex-content abt-text">
                            {/* <h2 className="uni-text">
                                {cmsData?.data?.home_page_content?.my_expertise?.title_1 && titleSplit(cmsData?.data?.home_page_content?.my_expertise?.title_1)}
                            </h2> */}
                            {cmsData?.data?.home_page_content?.my_expertise?.head_title && parse(cmsData?.data?.home_page_content?.my_expertise?.head_title)}
                            {cmsData?.data?.home_page_content?.my_expertise?.content && parse(cmsData?.data?.home_page_content?.my_expertise?.content)}
                            <div className="row my-4">
                                {cmsData?.data?.home_page_content?.my_expertise?.image_2 && <div className="col-sm-4 mb-3 mb-sm-0">
                                    <div className="overlay-wrap">
                                        <img
                                            src={cmsData?.data?.home_page_content?.my_expertise?.image_2}
                                            alt="johns"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>}
                                {cmsData?.data?.home_page_content?.my_expertise?.image_3 && <div className="col-sm-4 mb-3 mb-sm-0">
                                    <div className="overlay-wrap">
                                        <img
                                            src={cmsData?.data?.home_page_content?.my_expertise?.image_3}
                                            alt="johns"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>}
                                {cmsData?.data?.home_page_content?.my_expertise?.image_4 && <div className="col-sm-4">
                                    <div className="overlay-wrap">
                                        <img
                                            src={cmsData?.data?.home_page_content?.my_expertise?.image_4}
                                            alt="johns"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*My-experties-End=====*/}


        {/*Featured-Books-Star=====*/}
        <section className="featured-books p-top p-bottom">
            <div className="container">
                <div className="row align-items-center mb-2 mb-sm-5">
                    <div className="col-sm-7 mb-2 mb-sm-0">
                        {/* <h2 className="uni-text">
                            Featured <span>Books</span>
                        </h2> */}
                        {cmsData?.data?.home_page_content?.book?.head_title && parse(cmsData?.data?.home_page_content?.book?.head_title)}
                        {cmsData?.data?.home_page_content?.book?.content && parse(cmsData?.data?.home_page_content?.book?.content)}
                    </div>
                    <div className="col-sm-5 text-end">
                        <Link to="/books" className=" btn-primary">
                            View More
                        </Link>
                    </div>
                </div>


                {bookData?.data && <ReactOwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={40}
                    responsiveClass={true}
                    nav={true}
                    dots={false}
                    navText={["<", ">"]}

                    responsive={{
                        0: {
                            items: 1,

                        },
                        768: {
                            items: 2,

                        },
                        1000: {
                            items: 4
                        }
                    }}
                >
                    {bookData?.data?.map((item, index) => (<div key={index}>
                        <div className="item">
                            <div className="book-container" onClick={() => window.open(item.link)}>
                                <figure>
                                    <img
                                        src={item.image}
                                        alt="book"
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>))}
                </ReactOwlCarousel>}

            </div>
        </section >
        {/*Featured-Books-End=====*/}


        {/*Upcoming-events-start=====*/}
        <section className="upcoming-events p-top p-bottom">
            <div className="container">
                {/* <h2 className="uni-text">
                    Upcoming <span>Events</span>
                </h2> */}


                <div className="row align-items-center mb-2 mb-sm-5">
                    <div className="col-sm-7 mb-2 mb-sm-0">
                        {cmsData?.data?.home_page_content?.event?.head_title && parse(cmsData?.data?.home_page_content?.event?.head_title)}
                        {cmsData?.data?.home_page_content?.event?.content && parse(cmsData?.data?.home_page_content?.event?.content)}
                    </div>
                    <div className="col-sm-5 text-end">
                        <Link to="/news-events" className=" btn-primary">
                            View More
                        </Link>
                    </div>
                </div>

                <div className="row mt-4">

                    {eventData?.data?.map((item, index) => (<div key={index} className="col-md-4  mb-4 mb-md-0">
                        <div className="evnt-bx">
                            <figure>
                                <img
                                    src={item.image}
                                    alt="event"
                                    className="img-fluid"
                                />
                            </figure>
                            <div className="details">
                                <small>{moment(item.start_date).format('dddd')}</small>
                                <Link to="/news-events-details" state={{ id: item.id }}>
                                    <b>{item.title}</b>
                                </Link>
                                <Link to="/news-events-details" state={{ id: item.id }}>
                                    Know More <i className="fa-solid fa-angles-right" />
                                </Link>
                            </div>
                        </div>
                    </div>))}

                </div>
            </div>
        </section>
        {/*Upcoming-events-End=====*/}
    </>
    )
}

export default Home;