import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import parse from 'html-react-parser';

import ApiService from "../services/ApiService";
import moment from "moment";


function NewsEventsDetails() {

  var location = useLocation();

  const { data: singleEventData } = useQuery({
    queryKey: ['singleEventData', location.state.id],
    queryFn: async () => {
      const response = await new ApiService().GET(`/event/${location.state.id}`);
      return response.data;
    },
  });


  const { data: eventData } = useQuery({
    queryKey: ['latestEventData'],
    queryFn: async () => {
      const response = await new ApiService().GET("/event?page=1&perPage=2");
      return response.data;
    },
  });


  return (
    <>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-content mb-4">
                <h1>
                  {singleEventData?.data?.title}
                </h1>
                <span className="details-date">
                  {" "}
                  <i className="fa fa-calendar" />
                  {singleEventData?.data?.created_at && moment(singleEventData?.data?.created_at).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="details-pic">
                {singleEventData?.data?.image && <img
                  src={singleEventData?.data?.image}
                  width={600}
                  height={700}
                  alt="icon"
                  className="img-fluid"
                />}
              </div>
              <div className="blog-details-content mt-4">
                {singleEventData?.data?.long_description && parse(singleEventData?.data?.long_description)}
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="listing-blog">
                <ul>
                  {eventData?.data?.data?.map((item, index) => (<li key={index}>
                    <Link to="/news-events-details" state={{ id: item.id }}>
                      <div className="blog-list-pic">
                        <img
                          src={item?.image}
                          width={150}
                          height={150}
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog-list-content">
                        <span className="list-date">{item?.created_at && moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}</span>
                        <h4>
                          {item?.title}
                        </h4>
                      </div>
                    </Link>
                  </li>))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsEventsDetails;
