import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';


const initialState = {
    cmsData: {},
    cmsLoading: false,
    cmsError: ""
};


export const fetchCms = createAsyncThunk("fetchCms", async () => {
    try {

        const response = await new ApiService().GET("/cms-content");
        return response.data;

    } catch (err) {
        throw err;
    }
})

const cmsSlice = createSlice({
    name: 'cmsContent',
    initialState: initialState,
    reducers: {

        setCmsData(state, action) {
            state.cmsData = action.payload;
            // console.log("cmsData print: ", action.payload);
        },

        setCmsLoading(state, action) {
            state.cmsLoading = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchCms.pending, (state, action) => {

            state.cmsLoading = true;

        }).addCase(fetchCms.fulfilled, (state, action) => {

            state.cmsLoading = false;
            state.cmsData = action.payload.data;

        }).addCase(fetchCms.rejected, (state, action) => {

            state.cmsLoading = false;
            state.cmsError = action.payload || "Something went wrong!"

        })
    }
});




export const cmsActions = cmsSlice.actions;
export default cmsSlice.reducer;
