import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { thunk } from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';
// import { setupListeners } from "@reduxjs/toolkit/query";


///////////////////// declare all Reducer  //////////////////////////
import cmsReducer from './reducers/Cms';
///////////////////// End declare all Reducer  /////////////////////


///////////////////// declare all RTK Query  /////////////////////////
import { cmsApi } from "./query/cmsApi";
import { bookApi } from "./query/bookApi";
///////////////////// End declare all RTK Query  /////////////////////



const encryptionKey = 'didiergondola@key999';
const encryptor = encryptTransform({
    secretKey: encryptionKey,
    onError: function (error) {
        // Handle encryption errors here 
        console.error('Encryption error: ', error);
    },
});


const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
};


///////////////////// write all Reducer  ///////////////////////
const rootReducer = combineReducers({
    Cms: cmsReducer,

    ///////// Query //////////////
    [cmsApi.reducerPath]: cmsApi.reducer,
    [bookApi.reducerPath]: bookApi.reducer,
});
///////////////////// End write all Reducer ///////////////////


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: () => [
        thunk, 
        cmsApi.middleware,
        bookApi.middleware
    ],
});


// Redux query 
// setupListeners(store.dispatch); /// currently stop, use react-query

export const persistor = persistStore(store);