import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


////////// RTK query ///////////////
export const bookApi = createApi({
    reducerPath: 'fetchBookApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_SERVICE_URL }),
    endpoints: (builder) => ({

        getBooks: builder.query({
            query: () => ({
                url: `/book`,
                method: 'get'
            }),
        }),
    }),
});



export const { useGetBooksQuery } = bookApi;