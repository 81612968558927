import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


////////// RTK query ///////////////
export const cmsApi = createApi({
    reducerPath: 'fetchCmsApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_SERVICE_URL }),
    endpoints: (builder) => ({

        getCmsContent: builder.query({
            query: () => ({
                url: `/cms-content`,
                method: 'get'
            }),
        }),
    }),
});



export const { useGetCmsContentQuery } = cmsApi;