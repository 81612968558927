import $ from 'jquery';

// $(document).ready(function () {
//   $(".mobile-toggle").click(function () {
//     $(".mobile-toggle").toggleClass("menu-open");
//     $("ul").toggleClass("active");
//   });
// });

$('body').on('click', '.mobile-toggle', function () {
  $(".mobile-toggle").toggleClass("menu-open");
  $("ul").toggleClass("active");
})


// $(document).ready(function () {
//   $(window).scroll(function () {
//     if ($(this).scrollTop() > 10) {
//       $("#header-section").addClass("sticky");
//     } else {
//       $("#header-section").removeClass("sticky");
//     }
//   });
// });


// $('body').on('scroll', function () {
//   if ($(this).scrollTop() > 10) {
//     $("#header-section").addClass("sticky");
//   } else {
//     $("#header-section").removeClass("sticky");
//   }
// })


$(window).scroll(function() {
  if ($(this).scrollTop() > 1){  
      $('#header-section').addClass("sticky");
    }
    else{
      $('#header-section').removeClass("sticky");
    }
  });




