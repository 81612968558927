import { Link } from "react-router-dom"

import ReactOwlCarousel from "react-owl-carousel";
import { useQuery } from "@tanstack/react-query";
import ApiService from "../services/ApiService";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";


function AwardHonors() {

    let ImgLR = false;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData,cmsLoading } = useSelector(state => state.Cms);


    const { data: awardData } = useQuery({
        queryKey: ['awardData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/award?perPage=all");
            return response.data.data;
        },
    });


    const displayResult = (index, item) => {

        var data = (<div key={index} className={`award-wraper ${ImgLR && 'wrap-two'} mb-5`}>
            <div className="position ">
                <img
                    src={item.image}
                    alt="award"
                    className="img-fluid"
                />
            </div>
            <div className="content-de">
                <div className="top-bar" />
                <h4>{item.title}</h4>
                {parse(item.short_description)}
                <Link to="/award-honors-details" state={{ id: item.id }} className="readmore">
                    LEARN MORE
                </Link>
            </div>
        </div>);

        ImgLR = !ImgLR;

        return data;

    }

    return (<>

        {/* ////////// page title set /////////////////// */}
        <Helmet>
            <title>DG || Awards &amp; Honors</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
        {/* ///////// page title set End //////////////// */}


        {/* Inner-banner-start=====*/}
        {/* <section
            className="inner-banner"
            style={{ backgroundImage: `url("${awarda_honors_bg}")` }}
        >
            <div className="container">
                <div className="content">
                    <h1>Awards &amp; Honors</h1>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb m-0 justify-content-center">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Awards &amp; Honors
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section> */}
        <section className="hero">

            {cmsData?.data?.cms_banner?.awardHonors_page && <ReactOwlCarousel
                className='owl-theme'
                loop={true}
                margin={0}
                responsiveClass={true}
                nav={true}
                navText={['<','>']}
                dots={false}
                autoplay={true}
                autoplayTimeout={10000}

                responsive={{
                    0: {
                        items: 1,

                    },
                    768: {
                        items: 1,

                    },
                    1000: {
                        items: 1
                    }
                }}
            >
                {cmsData?.data?.cms_banner?.awardHonors_page?.map((item, index) => (<div key={index}>
                    <div className="item">
                        <div
                            className="hero-bg"
                            style={{ backgroundImage: `url(${item.image})`, height: "400px" }}
                        />
                    </div>
                </div>))}
            </ReactOwlCarousel>}

        </section >
        {/* Inner-banner-end=====*/}


        {/* Award-sec-n-start=====*/}
        <section className="award-sec-n p-top p-bottom">
            <div className="container">
                {awardData?.map((item, index) => displayResult(index, item))}
            </div>
        </section>
        {/* Award-sec-n-end=====*/}
    </>
    )
}

export default AwardHonors