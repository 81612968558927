

import ReactOwlCarousel from "react-owl-carousel";
import { useQuery } from "@tanstack/react-query";
import ApiService from "../services/ApiService";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";


function Book() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData, cmsLoading } = useSelector(state => state.Cms);


    const { data: bookData } = useQuery({
        queryKey: ['all_bookData'],
        queryFn: async () => {
            const response = await new ApiService().GET("/book?perPage=all");
            if (response.data.status) {
                return response.data.data;
            } else {
                return response.data;
            }
        },
    });



    return (<>

        {/* ////////// page title set /////////////////// */}
        <Helmet>
            <title>DG || Books</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
        {/* ///////// page title set End //////////////// */}


        {/* Inner-banner-start=====*/}
        {/* <section
            className="inner-banner"
            style={{ backgroundImage: `url("${books_banner}")` }}
        >
            <div className="container">
                <div className="content">
                    <h1>Books</h1>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb m-0 justify-content-center">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Library
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section> */}
        <section className="hero">

            {cmsData?.data?.cms_banner?.book_page && <ReactOwlCarousel
                className='owl-theme'
                loop={true}
                margin={0}
                responsiveClass={true}
                nav={true}
                navText={['<','>']}
                dots={false}
                autoplay={true}
                autoplayTimeout={10000}

                responsive={{
                    0: {
                        items: 1,

                    },
                    768: {
                        items: 1,

                    },
                    1000: {
                        items: 1
                    }
                }}
            >
                {cmsData?.data?.cms_banner?.book_page?.map((item, index) => (<div key={index}>
                    <div className="item">
                        <div
                            className="hero-bg"
                            style={{ backgroundImage: `url(${item.image})`, height: "400px" }}
                        />
                    </div>
                </div>))}
            </ReactOwlCarousel>}

        </section >
        {/* Inner-banner-end=====*/}


        {/*Featured-Books-Star=====*/}
        <section className="featured-books p-top p-bottom">
            <div className="container">
                <div className="row">
                    {bookData?.map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 mb-4" onClick={() => window.open(item.link)}>
                            <div className="book-container">
                                <figure>
                                    <img
                                        src={item.image}
                                        alt="book"
                                        className="img-fluid"
                                    />
                                </figure>
                                <div className="btm-title">
                                    <small>{item.title}</small>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        {/*Featured-Books-End=====*/}
    </>
    )
}

export default Book