import { Link, NavLink } from "react-router-dom"

import email_img from "../../assets/images/icon/email.svg";
import { useSelector } from "react-redux";


function Footer() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { cmsData } = useSelector(state => state.Cms);


    return (<>
        {/*footer-Start=====*/}
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2  mb-4 mb-md-0">
                        <div className="ftr-logo">
                            <NavLink to="#">
                                <img
                                    src={cmsData?.data?.setting?.footer_logo?.resize_url}
                                    alt="footer-logo"
                                    className="img-fluid"
                                />
                            </NavLink>
                            {/* <p>
                                My specialization and research interests revolve around popular cultures, interstitial youth groups, and masculinities in Central Africa and the African Diaspora in France.
                            </p> */}
                            {/* <p>
                                Powered by{" "}
                                <Link
                                    to="https://www.thetechonpoint.com"
                                    target="_blank"
                                    style={{ color: "#f49423" }}
                                >
                                    The Tech On Point, Inc.
                                </Link>
                            </p>
                            <Link
                                to="https://www.thetechonpoint.com"
                                target="_blank"
                                style={{ color: "#f49423" }}
                            >
                                www.thetechonpoint.com
                            </Link> */}
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 mb-4 mb-md-0">
                        <div className="widget">
                            <h3>Quick Links</h3>
                            <ul>
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/books">Books</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/bibliography">Bibliography</NavLink>
                                </li> */}
                                <li>
                                    <NavLink to="/award-honors">Awards &amp; Honors</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/news-events">News &amp; Events</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/blogs-articles">Blogs</NavLink>
                                </li> */}
                                <li>
                                    <NavLink to="/contact-me">Contact Me</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="widget">
                            <h3>Contact Me</h3>
                            <div className="contact">
                                <Link to={`mailto:${cmsData?.data?.social_links_email?.contact_email?.details}`}>
                                    <img src={email_img} alt="email" />
                                    {cmsData?.data?.social_links_email?.contact_email?.details}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="copy-right">
                    <p>© Copyright 2024. All Right Reserved</p>
                </div> */}
                <div className="row copy-right-new">
                    <div className="col-lg-6 col-md-5 mb-4 mb-md-0">
                        <p>© Copyright 2024. All Right Reserved</p>
                    </div>
                    <div className="col-lg-6 col-md-5 mb-4 mb-md-0">

                        <p>Powered by <Link to="https://www.thetechonpoint.com" target="_blank" style={{ color: "#dfdfdf" }}  >The Tech On Point, Inc.&nbsp;&nbsp;</Link>
                            <Link to="https://www.thetechonpoint.com" target="_blank" style={{ color: "#dfdfdf" }} >www.thetechonpoint.com</Link></p>
                    </div>
                </div>
            </div>
        </footer>
        {/*footer-End=====*/}
    </>
    )
}

export default Footer