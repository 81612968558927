import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import parse from 'html-react-parser';

import ApiService from "../services/ApiService";
import moment from "moment";

function BlogsArticlesDetails() {

  var location = useLocation();

  const { data: singleBlogData} = useQuery({
    queryKey: ['singleBlogData',location.state.id],
    queryFn: async () => {
      const response = await new ApiService().GET(`/blog/${location.state.id}`);
      return response.data;
    },
  });


  const { data: blogData } = useQuery({
    queryKey: ['latestBlogData'],
    queryFn: async () => {
      const response = await new ApiService().GET("/blog");
      return response.data;
    },
  });

  


  return (
    <>
      {/* Blog-Details-start===*/}
      <section className="blog-details">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <div className="blog-details-content mb-4">
                <h1>
                  {singleBlogData?.data?.title}
                </h1>
                <span className="details-date">
                  {" "}
                  <i className="fa fa-calendar" />
                  {singleBlogData?.data?.created_at && moment(singleBlogData?.data?.created_at).format('DD-MM-YYYY')}
                </span>
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="details-pic">
                {singleBlogData?.data?.image && <img
                  src={singleBlogData?.data?.image}
                  width={600}
                  height={700}
                  alt="icon"
                  className="img-fluid"
                />}
              </div>
              <div className="blog-details-content mt-4">
                {singleBlogData?.data?.long_description && parse(singleBlogData?.data?.long_description)}
              </div>
            </div>

            {blogData?.latest_data && <>
              <div className="col-lg-4 col-md-12">
                <div className="listing-blog">
                  <ul>


                    {blogData?.latest_data?.item_1 && <li key={1}>
                      <Link to="/blogs-articles-details" state={{ id: blogData?.latest_data?.item_1?.id }}>
                        <div className="blog-list-pic">
                          <img
                            src={blogData?.latest_data?.item_1?.image}
                            width={150}
                            height={150}
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blog-list-content">
                          <span className="list-date">{blogData?.latest_data?.item_1?.created_at && moment(blogData?.latest_data?.item_1?.created_at).format('DD-MM-YYYY HH:mm:ss')}</span>
                          <h4>
                            {blogData?.latest_data?.item_1?.title}
                          </h4>
                        </div>
                      </Link>
                    </li>}

                    {blogData?.latest_data?.item_2 && <li key={2}>
                      <Link to="/blogs-articles-details" state={{ id: blogData?.latest_data?.item_2?.id }}>
                        <div className="blog-list-pic">
                          <img
                            src={blogData?.latest_data?.item_2?.image}
                            width={150}
                            height={150}
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blog-list-content">
                          <span className="list-date">{blogData?.latest_data?.item_2?.created_at && moment(blogData?.latest_data?.item_2?.created_at).format('DD-MM-YYYY HH:mm:ss')}</span>
                          <h4>
                            {blogData?.latest_data?.item_2?.title}
                          </h4>
                        </div>
                      </Link>
                    </li>}

                  </ul>
                </div>
              </div>
            </>}

          </div>
        </div>
      </section>

      {/* Blog-Details-end===*/}
    </>
  );
}

export default BlogsArticlesDetails;
